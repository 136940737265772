import { ethers } from 'ethers'
import { CoinAddress, LockingPeriod, PoolDWC180, PoolDWC30, PoolDWC360, PoolDWC7, PoolDWC90, PoolUSDT180, PoolUSDT30, PoolUSDT360, PoolUSDT7, PoolUSDT90, USDT, defaultNetwork, web3Provider } from '../config'
import StakeCoin from "../abi/StakeCoin.json"
import ERCAbi from "../abi/erc20.json"
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAccount, useNetwork } from "wagmi";
import { toLocale, useContractInstance } from './useContract'
import { createPublicClient, formatUnits, http, parseUnits } from 'viem'
import { toast } from "react-hot-toast"
import OracleAbi from "../abi/PoolOracle.json"
import { OracleAddress } from '../config'
import { useQueryAndSetAddress } from './userAddress'
import { formatEther } from 'ethers/lib/utils'


const polygonMumbai = {
    id: 80001,
    name: "Polygon Mumbai",
    network: "maticmum",
    nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
    },
    rpcUrls: {
        alchemy: {
            http: ["https://polygon-mumbai.g.alchemy.com/v2"],
            webSocket: ["wss://polygon-mumbai.g.alchemy.com/v2"],
        },
        infura: {
            http: ["https://polygon-mumbai.infura.io/v3"],
            webSocket: ["wss://polygon-mumbai.infura.io/ws/v3"],
        },
        default: {
            http: ["https://special-polished-lake.matic-testnet.quiknode.pro/241402282ea6046c7d4516d7ec9610cad44e9838/"],
        },
        public: {
            http: ["https://special-polished-lake.matic-testnet.quiknode.pro/241402282ea6046c7d4516d7ec9610cad44e9838/"],
        },
    },
    blockExplorers: {
        etherscan: {
            name: "PolygonScan",
            url: "https://mumbai.polygonscan.com",
        },
        default: {
            name: "PolygonScan",
            url: "https://mumbai.polygonscan.com",
        },
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 25770160,
        },
    },
    testnet: true,
};

const polygon = {
    id: 137,
    name: "Polygon",
    network: "matic",
    nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
    },
    rpcUrls: {
        alchemy: {
            http: ["https://polygon-mainnet.g.alchemy.com/v2"],
            webSocket: ["wss://polygon-mainnet.g.alchemy.com/v2"],
        },
        infura: {
            http: ["https://polygon-mainnet.infura.io/v3"],
            webSocket: ["wss://polygon-mainnet.infura.io/ws/v3"],
        },
        default: {
            http: ["https://virulent-soft-glade.matic.quiknode.pro/f31fd1dab926f81b6236cd10e84ced895dd6ddf7/"],
        },
        public: {
            http: ["https://virulent-soft-glade.matic.quiknode.pro/f31fd1dab926f81b6236cd10e84ced895dd6ddf7/"],
        },
    },
    blockExplorers: {
        etherscan: {
            name: "PolygonScan",
            url: "https://polygonscan.com",
        },
        default: {
            name: "PolygonScan",
            url: "https://polygonscan.com",
        },
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 25770160,
        },
    },
};





export const Stake = (poolAddress) => {
    const { address } = useAccount()
    const { poolDetails } = UsePoolData()
    const poolData = poolDetails.find(item => item.pool.toLowerCase() === poolAddress.toLowerCase())

    const stakeInstance = useContractInstance(poolAddress, StakeCoin, address !== undefined && address !== null)

    const execute = useCallback((amount, refer) => {
        return new Promise(async (resolve, reject) => {

            // Check if the refer parameter is exactly the string "null"
            // if (refer === "null") {
            //     toast.error("You do not have a referrer.");
            //     return resolve(null);
            // }
            try {
                let stakeAmount = ethers.utils.parseUnits(amount, poolData.coin === "DWC" ? 18 : 6);
                const transaction = await stakeInstance.Stake(stakeAmount,
                    (refer !== "null" ? refer : ethers.constants.AddressZero)
                );

                const loadingToast = await toast.promise(transaction.wait(), {
                    loading: "Staking...",
                    success: "Staked Successfully",
                    error: "Failed to Stake",
                });
                toast.dismiss(loadingToast);
                resolve(true)

            } catch (err) {
                console.warn(err);
                resolve(null)
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
            }
        })
    }, [stakeInstance, poolData.coin])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])


};


export const Approve = (pool) => {
    const { address } = useAccount()
    const { poolDetails } = UsePoolData()
    const poolData = poolDetails.find(item => item.pool.toLowerCase() === pool.toLowerCase())

    const Token = useContractInstance(poolData.coin === "DWC" ? CoinAddress : USDT, ERCAbi, address !== undefined && address !== null)

    const execute = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            try {
                const txn = await Token.approve(pool, ethers.constants.MaxUint256);
                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Approving...",
                    success: "Approved Successfully",
                    error: "Failed to Approve",
                });
                toast.dismiss(loadingToast);

            } catch (err) {
                if (address === undefined) {
                    toast.error("Connect Wallet")
                }
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)
            }
        })
    }, [Token, pool, address])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])


}


export const ClaimCoin = (pool) => {
    const { address } = useAccount()


    const stakeInstance = useContractInstance(pool, StakeCoin, address !== undefined && address !== null)
    const execute = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            try {

                const transaction = await stakeInstance.UserClaimReward();
                const loadingToast = await toast.promise(transaction.wait(), {
                    loading: "Claiming...",
                    success: "Claimed Successfully",
                    error: "Failed to Claim",
                });
                toast.dismiss(loadingToast);

            } catch (err) {
                console.error(err);
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)

            }
        })
    }, [stakeInstance])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])

};


export const RemoveStake = (pool) => {
    const { address } = useAccount()

    return;    

    const stakeInstance = useContractInstance(pool, StakeCoin, address !== undefined && address !== null)

    const execute = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            try {

                const transaction = await stakeInstance.RemoveStake();
                const loadingToast = await toast.promise(transaction.wait(), {
                    loading: "Removing...",
                    success: "Removed Successfully",
                    error: "Failed to Remove",
                });
                toast.dismiss(loadingToast);

            } catch (err) {
                console.error(err);
                resolve(null)
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)

            }
        })
    }, [stakeInstance])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])

}


export const GetReward = () => {
    const [Data, setData] = useState(0);
    const { poolDetails } = UsePoolData()

    const execute = useCallback((val, pool) => {
        return new Promise(async (resolve, reject) => {
            try {
                const stakeInstance = new ethers.Contract(pool, StakeCoin, web3Provider)
                const amount = parseUnits(val, GetCoinByPool(poolDetails, pool).coin === "DWC" ? 18 : 6)
                let Data = await stakeInstance.getRewardData(amount);
                Data = formatEther(Data)
                setData(Data)


                resolve(true)
            } catch (err) {
                resolve(null)
                console.warn(err);
            }
        })
    }, [poolDetails])

    return useMemo(() => {
        return {
            execute,
            Data,
        }
    }, [execute, Data])
}


export function Alldata(pool) {
    const { chain } = useNetwork()
    const { poolDetails } = UsePoolData()
    const isCorrectNetwork = chain?.id === defaultNetwork
    const { address } = useQueryAndSetAddress()

    const [allowance, setAllowance] = useState(0);
    const [HfgBalance, setHfgBalance] = useState(0);
    const poolData = poolDetails.find(item => item.pool.toLowerCase() === pool.toLowerCase())

    const Token = useContractInstance(poolData.coin === "DWC" ? CoinAddress : USDT, ERCAbi)

    const fetchHFGTokenInfo = useCallback(async () => {
        try {

            const hfgAllowance = await Token.allowance(address, pool)
            const _hfgAllowance = formatUnits(hfgAllowance, poolData.coin === "DWC" ? 18 : 6)
            setAllowance(_hfgAllowance)

            const hfgBalance = await Token.balanceOf(address)
            const _hfgBalance = formatUnits(hfgBalance, poolData.coin === "DWC" ? 18 : 6)
            setHfgBalance(_hfgBalance)

        } catch (err) {
            console.error(err)
        }
    }, [Token, address, pool, poolData?.coin])


    useEffect(() => {
        const interval = setInterval(async () => {
            if (address) {
                fetchHFGTokenInfo()
            }

        }, 8000);
        return () => clearInterval(interval);
    }, [fetchHFGTokenInfo, address, pool]);

    useEffect(() => {
        if (address) {

            fetchHFGTokenInfo();
        }

    }, [fetchHFGTokenInfo, address, pool])


    return useMemo(() => {
        return {
            allowance,
            HfgBalance
        }
    }, [allowance, HfgBalance])

}


export function ReferralOuter() {
    const { address } = useQueryAndSetAddress()

    const [referedBy, setReferedBy] = useState(0);
    const [canRefer, setCanRefer] = useState(false);
    const [referralArray, setreferralArray] = useState(false);

    const OracleInt = useContractInstance(OracleAddress, OracleAbi)

    const getUserPoolData = useCallback(async () => {
        try {
            const data = await OracleInt.UserReferalData(address)
            let refArray = await OracleInt.getPercentReferalEarn();
            setreferralArray(refArray)
            if (data?.length > 0) {
                if (data[0] === ethers.constants.AddressZero) {
                    setReferedBy(0);
                }
                else {
                    setReferedBy(data[0])
                }
                setCanRefer(data[1])
            }
        } catch (error) {
            console.error(error);
        }

    }, [address, OracleInt])

    useEffect(() => {
        if (address) {
            getUserPoolData()
        } else {
            setReferedBy(0);
            setCanRefer(false);
        }
    }, [address])

    useEffect(() => {
        const interval = setInterval(async () => {
            if (address) {
                getUserPoolData()
            }

        }, 12000);
        return () => clearInterval(interval);
    }, [getUserPoolData, address]);

    return useMemo(() => {
        return {
            referedBy,
            canRefer,
            referralArray
        }
    }, [referedBy, canRefer, referralArray])
}


export function UserCalling(pool) {
    const { address } = useQueryAndSetAddress()

    const { poolDetails } = UsePoolData()
    const [Timer, setTimer] = useState(0);
    const [TotalReward, setTotalreward] = useState(0);
    const [tag, setTag] = useState(0);
    const [claimedReward, setClaimedReward] = useState(0);
    const [claimableReward, setClaimableReward] = useState(0);
    const [stakeAmount, setStakeAmount] = useState(0);

    const poolData = poolDetails.find(item => item.pool.toLowerCase() === pool.toLowerCase())
    const client = createPublicClient({
      chain: polygon, // polygonMumbai
      transport: http(),
    });

    const decimals = poolData.coin === "DWC" ? 18 : 6

    const getUserPoolData = useCallback(async () => {

        try {

            const data = await client.multicall({
                contracts: [
                    {
                        address: pool,
                        abi: StakeCoin,
                        functionName: 'getStakedAmount',
                        args: [address],
                    },
                    {
                        address: pool,
                        abi: StakeCoin,
                        functionName: 'getClaimedReward',
                        args: [address],
                    },
                    {
                        address: pool,
                        abi: StakeCoin,
                        functionName: 'getClaimableReward',
                        args: [address],
                    },

                    {
                        address: pool,
                        abi: StakeCoin,
                        functionName: 'getUnStakeTime',
                        args: [address],
                    },
                    {
                        address: pool,
                        abi: StakeCoin,
                        functionName: 'getTotalReward',
                        args: [address],
                    },
                ],
                allowFailure: false
            })

            if (data?.length > 0) {
                setStakeAmount(formatUnits(data[0], decimals))
                setClaimedReward(formatEther(data[1]))
                setClaimableReward(formatEther(data[2]))
                setTotalreward(formatEther(data[4]))
                setTimer(parseFloat(data[3]))
                if ((parseFloat(data[3]) * 1000) > Date.now()) {
                    setTag(0)
                } else {
                    if (((parseFloat(data[3]) + LockingPeriod) * 1000) < Date.now()) {
                        setTag(1)
                    } else {
                        setTag(2)
                    }
                }
            }

        } catch (error) {
            console.error(error);
        }

    }, [address, client, pool, decimals])

    useEffect(() => {
        if (address) {
            getUserPoolData()
        } else {
            setStakeAmount(0)
            setClaimedReward(0)
            setClaimableReward(0)
            setTotalreward(0)
        }
    }, [address, pool])

    useEffect(() => {
        const interval = setInterval(async () => {
            if (address) {
                getUserPoolData()
            }

        }, 8000);
        return () => clearInterval(interval);
    }, [getUserPoolData, address, pool]);

    return useMemo(() => {
        return {
            stakeAmount,
            claimableReward,
            claimedReward,
            tag,
            Timer,
            TotalReward,

        }
    }, [stakeAmount, claimableReward, claimedReward, tag, Timer, TotalReward])
}


export function AdminFunction() {
    const { address } = useAccount()
    const [enable, setenable] = useState(false)

    const zebToken = useContractInstance(CoinAddress, ERCAbi, address !== undefined && address !== null)
    const USDTToken = useContractInstance(USDT, ERCAbi, address !== undefined && address !== null)
    const OracleInt = useContractInstance(OracleAddress, OracleAbi, address !== undefined && address !== null)

    const AddLiquidity = useCallback((token, amount, pool) => {
        return new Promise(async (resolve, reject) => {
            try {
                setenable(true)
                if (!amount) {
                    setenable(false)
                    toast.error("Please enter Amount");
                    resolve(false); // resolving with failure flag
                    return;
                }

                const isCoin = token.toLowerCase() === CoinAddress.toLowerCase();
                const decimalPlaces = isCoin ? 18 : 6;
                const targetToken = isCoin ? zebToken : USDTToken;

                const convertAmount = parseUnits(amount, decimalPlaces);

                const txn = await targetToken.transfer(pool, convertAmount);

                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Processing...",
                    success: "Transaction Successfully",
                    error: "Failed",
                });
                toast.dismiss(loadingToast);
                setenable(false)

                resolve(true);
            } catch (err) {
                setenable(false)
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message);
                resolve(null);
            }
        });
    }, [zebToken, USDTToken]);


    const SetpercentageReturn = useCallback((coin, percentage, pool) => {
        return new Promise(async (resolve, reject) => {
            try {
                setenable(true)

                if (!percentage) {
                    setenable(false)

                    toast.error("Please enter value");
                    resolve(false);
                    return;
                }
                const abi = ["function setPercentageReturn(uint) external"]
                const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner()
                const contract = new ethers.Contract(pool, abi, signer)
                const txn = await contract.setPercentageReturn(parseUnits(percentage, 4));

                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Processing...",
                    success: "Transaction Successfully",
                    error: "Failed",
                });
                setenable(false)

                toast.dismiss(loadingToast);
                resolve(true);
            } catch (err) {
                setenable(false)

                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message);
                resolve(null);
            }
        });
    }, []);


    const SetReferralPercentage = useCallback((level, percentage) => {
        return new Promise(async (resolve, reject) => {
            try {
                setenable(true)

                if (!percentage || !level) {
                    setenable(false)

                    toast.error("Please enter value");
                    resolve(false);
                    return;
                }

                let refArray = await OracleInt.getPercentReferalEarn();
                let modifiableArray = [...refArray];
                modifiableArray[Number(level)] = Number(percentage);

                const txn = await OracleInt.setPrecentReferalEarn(modifiableArray);

                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Processing...",
                    success: "Transaction Successfully",
                    error: "Failed",
                });
                setenable(false)

                toast.dismiss(loadingToast);
                resolve(true);
            } catch (err) {
                setenable(false)

                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message);
                resolve(null);
            }
        });
    }, [OracleInt]);


    const EmergencyWithdraw = useCallback((token, amount, pool) => {
        return new Promise(async (resolve, reject) => {
            try {
                setenable(true)
                if (!window.ethereum) {
                    setenable(false)
                    toast.error("Please Connet Wallet");
                    resolve(false);
                    return;
                }
                if (!amount) {
                    setenable(false)
                    toast.error("Please enter Amount");
                    resolve(false);
                    return;
                }
                const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner()
                const contract = new ethers.Contract(pool, StakeCoin, signer)
                const convertAmount = parseUnits(amount, token === CoinAddress ? 18 : 6)
                const txn = await contract.emergencyWithdraw(token, convertAmount)

                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Processing...",
                    success: "Transaction Successfully",
                    error: "Failed",
                });
                setenable(false)
                toast.dismiss(loadingToast);


            } catch (err) {
                setenable(false)
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)
            }
        })
    }, [])


    return useMemo(() => {
        return {
            AddLiquidity,
            EmergencyWithdraw,
            SetpercentageReturn,
            SetReferralPercentage,
            enable,
        }
    }, [AddLiquidity, EmergencyWithdraw, SetpercentageReturn, SetReferralPercentage, enable])


}


const initialState = {
    [PoolDWC7]: 0,
    [PoolDWC30]: 0,
    [PoolDWC90]: 0,
    [PoolDWC180]: 0,
    [PoolDWC360]: 0,
    [PoolUSDT7]: 0,
    [PoolUSDT30]: 0,
    [PoolUSDT90]: 0,
    [PoolUSDT180]: 0,
    [PoolUSDT360]: 0,
};




export function ContractBalance() {

    const [balance, setBalance] = useState(initialState);
    const [balance2, setBalance2] = useState(initialState);
    const { poolDetails } = UsePoolData()
    const fetchTokenInfo = useCallback(async () => {
        const client = createPublicClient({
            chain: polygon, // polygonMumbai
            transport: http(),
        });

        const contractCalls = poolDetails.map(pool => ({
            address: CoinAddress,
            abi: ERCAbi,
            functionName: 'balanceOf',
            args: [pool.pool],
        }));

        const contractCalls2 = poolDetails.map(pool => ({
            address: USDT,
            abi: ERCAbi,
            functionName: 'balanceOf',
            args: [pool.pool],
        }));

        try {
            const data = await client.multicall({ contracts: contractCalls, allowFailure: false });
            const data2 = await client.multicall({ contracts: contractCalls2, allowFailure: false });

            if (data.length > 0) {
                setBalance({
                    [PoolDWC7]: formatEther(data[0]),
                    [PoolDWC30]: formatEther(data[1]),
                    [PoolDWC90]: formatEther(data[2]),
                    [PoolDWC180]: formatEther(data[3]),
                    [PoolDWC360]: formatEther(data[4]),
                    [PoolUSDT7]: formatEther(data[5]),
                    [PoolUSDT30]: formatEther(data[6]),
                    [PoolUSDT90]: formatEther(data[7]),
                    [PoolUSDT180]: formatEther(data[8]),
                    [PoolUSDT360]: formatEther(data[9]),
                });
            }
            if (data2.length > 0) {
                setBalance2({
                    [PoolDWC7]: formatUnits(data2[0], 6),
                    [PoolDWC30]: formatUnits(data2[1], 6),
                    [PoolDWC90]: formatUnits(data2[2], 6),
                    [PoolDWC180]: formatUnits(data2[3], 6),
                    [PoolDWC360]: formatUnits(data2[4], 6),
                    [PoolUSDT7]: formatUnits(data2[5], 6),
                    [PoolUSDT30]: formatUnits(data2[6], 6),
                    [PoolUSDT90]: formatUnits(data2[7], 6),
                    [PoolUSDT180]: formatUnits(data2[8], 6),
                    [PoolUSDT360]: formatUnits(data2[9], 6),
                });
            }

        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }, [poolDetails]);



    useEffect(() => {
        const interval = setInterval(async () => {
            fetchTokenInfo()

        }, 12000);
        return () => clearInterval(interval);
    }, [fetchTokenInfo]);

    useEffect(() => {
        fetchTokenInfo();
    }, [])

    return useMemo(() => {
        return {
            balance,
            balance2
        }
    }, [balance, balance2])

}



const staticPoolDetails = [
    { pool: PoolDWC7, percent: 6.39, days: 7, coin: "DWC", label: "DWC-7" },
    { pool: PoolDWC30, percent: 8.21, days: 30, coin: "DWC", label: "DWC-30" },
    { pool: PoolDWC90, percent: 11.01, days: 90, coin: "DWC", label: "DWC-90" },
    { pool: PoolDWC180, percent: 14.21, days: 180, coin: "DWC", label: "DWC-180" },
    { pool: PoolDWC360, percent: 18.08, days: 360, coin: "DWC", label: "DWC-360" },
    { pool: PoolUSDT7, percent: 4.25, days: 7, coin: "USDT", label: "USDT-7" },
    { pool: PoolUSDT30, percent: 6.39, days: 30, coin: "USDT", label: "USDT-30" },
    { pool: PoolUSDT90, percent: 8.21, days: 90, coin: "USDT", label: "USDT-90" },
    { pool: PoolUSDT180, percent: 10.08, days: 180, coin: "USDT", label: "USDT-180" },
    { pool: PoolUSDT360, percent: 12.01, days: 360, coin: "USDT", label: "USDT-360" },
];


export const UsePoolData = () => {
    const [poolDetails, setPoolDetails] = useState(staticPoolDetails);

    const fetchPoolData = useCallback(async () => {

        const client = createPublicClient({
          chain: polygon,  // polygonMumbai
          transport: http(),
        });
        const contractCalls = staticPoolDetails.map(pool => ({
            address: pool.pool,
            abi: StakeCoin,
            functionName: 'PercentageReturn',
        }));

        try {
            const data = await client.multicall({ contracts: contractCalls, allowFailure: false });
            // console.log("Data fetched: ", data);

            const updatedPoolDetails = data.map((poolData, index) => {
                const percentValue = parseInt(poolData);
                return {
                    ...staticPoolDetails[index],
                    percent: !isNaN(percentValue) ? percentValue / 10000 : staticPoolDetails[index].percent,
                };
            });

            setPoolDetails(updatedPoolDetails);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }, []);

    // useEffect(() => {
    //     fetchPoolData();
    // }, [fetchPoolData])

    useEffect(() => {
        const interval = setInterval(fetchPoolData, 12000);
        return () => clearInterval(interval);
    }, [fetchPoolData]);

    return useMemo(() => {
        return {
            poolDetails
        }
    }, [poolDetails])

};




export const GetCoinByPool = (poolDetails, poolName) => {

    const pool = poolDetails.find(p => p.pool.toLowerCase() === poolName.toLowerCase());

    return pool ? pool : null;
}


export const CalculateReward = (poolDetails, amount, poolName) => {
    try {

        const pool = poolDetails.find(p => p.pool.toLowerCase() === poolName.toLowerCase());

        if (!pool) {
            return "Invalid pool details";
        }
        const reward = amount * (pool.percent / 100) * pool.days / 360;
        return toLocale(reward);
    } catch (error) {
        console.error(error);
    }
};